[
  {
    "eng": {
      "title": "intro",
      "text": "<p><b><h1>DASforce.com</h1></b> is a central-european based provider of development services, designed to improve automation and efficiency of <b>salesforce.com (SFDC)</b> based solutions</p><p>Our portfolio of SFDC services is diverse, from development at its core, administration, maintenance, consultancy and analytics, to customer support of ongoing solutions</p><p>Founded in 2022 by seasoned IT/consultancy professionals, we entered the market with<b><ul><li>competetive pricing<li>delivery quality<li>flexibility of smaller size company and agile project teams</ul></b></p><p>Our offices are currently based in <ul> <li><b>Prague, CZ <li>Bratislava, SK</ul></b></p><p>We support global enterprise partners but also local solutions covering <b>7 languages (EN, DE, CZ, SK, HU, RU, IT)</b></p><p>We look forward to developing professional relationships with current and future partners!</p>"
    },
    "cze": {
      "title": "úvod",
      "text": "<p><b><h1>DASforce.com</h1></b>  je společnost zabývající se vývojem softwarových řešení na bázi <b>salesforce.com (SFDC) </b> za účelem zefektivnění automatizace procesů u svých partnerů v podnikání</p><p>Portfolio našich služeb je široké, od vývoje aplikací, přes administraci, údržbu a konzultantské služby, až po analytiku a customer service implementovaných řešení</p><p>Společnost založená v 2022 skupinou IT a konzultantských profesionálů s více než 20 letými zkušenostmi, vstoupila na trh s<b><ul><li>konkurenčně výhodnými cenami<li>nadstandardní kvalitou služeb<li>flexibilitou menší firmy s agilními projektovými týmy</ul><b></p><p>Naše kanceláře momentálně sídlí v <ul> <li><b>Praze, ČR <li>Bratislavě, SR</ul></b></p><p>Podporujeme řešení pro globální korporátní klienty i menší lokální partnery s využitím <b>7 jazyků (EN, DE, CZ, SK, HU, RU, IT). </b></p><p>Těšíme se na rozvíjení spolupráce se stávajícími i budoucími partnery!"
    },
    "svk": {
      "title": "úvod",
      "text": "<p><b><h1>DASforce.com</h1></b>  je spoločnosť zaoberajúca sa vývojom softwarových riešení na báze <b>salesforce.com (SFDC) </b> za účelom zefektívnenia automatizácie procesov u svojich partnerov v podnikaní</p><p>Portfólio našich služieb je široké, od vývoja aplikácií, cez administráciu, údržbu a konzultantské služby, až po analytiku a customer service implementovaných riešení</p><p>Spoločnosť založená v 2022 skupinou IT a konzultantských profesionálov s viac ako 20 ročnými skúsenosťami, vstúpila na trh s<b><ul><li>konkurenčne výhodnými cenami<li>nadštandardnou kvalitou služieb<li>flexibilitou menšej firmy s agilnými projektovými tímami</ul></b></p><p>Naše kancelárie momentálne sídlia v <ul> <li><b>Prahe, ČR <li>Bratislave, SR</ul></b></p><p>Podporujeme riešenia pre globálnych korporátnych klientov ako aj menších lokálnych partnerov s využitím <b>7 jazykov (EN, DE, CZ, SK, HU, RU, IT). </b></p><p>Tešíme sa na rozvíjanie spolupráce s existujúcimi ako aj budúcimi partnermi!"
    },
    "deu": {
      "title": "über uns",
      "text": "<p><b><h1>DASforce.com</h1></b> ist ein zentraleuropäischer Anbieter von Entwicklungsdienstleistungen, spezialisiert auf die Automatisierung und Verbesserung der Effizienz von <b>salesforce.com (SFDC)</b>-basierten Lösungen</p> <p>Unser Portfolio an SFDC-Services ist vielfältig, von der Kernentwicklung über Verwaltung, Wartung, Beratung und Analyse bis hin zum Kundensupport laufender Lösungen</p><p>Im Jahr 2022 von erfahrenen IT-/Beratungsfachleuten gegründet, traten wir ein dem Markt mit<b><ul><li>wettbewerbsfähigen Preisen<li>Fokus auf hohe Qualität <li>Flexibilität für kleinere Unternehmen und agile Projektteams</ul></b></p><p>Unsere Büros befinden sich derzeit in <ul> <li ><b>Prag, CZ <li>Bratislava, SK</ul></b></p><p>Wir unterstützen globale Unternehmenspartner, aber auch lokale Lösungen in <b>7 Sprachen (EN, DE, CZ, SK, HU, RU, IT)</b></p><p>Wir freuen uns darauf, professionelle Beziehungen mit aktuellen und zukünftigen Partnern aufzubauen!</p>"
    }
  },
  {
    "eng": {
      "title": "services",
      "text": "<h1>development services</h1><ul><li>design, build and deployment of salesforce.com (SFDC) applications using Apex, Visualforce, Aura and the Lightning Web Components Framework to leverage the full capabilities of the SFDC platform<li>maintain, modify and administer existing partners' SFDC solutions<li>day-to-day contact for assigned projects, functional groups, and channels</ul><h1>consultancy services</h1><ul><li>analysis, application design, configuration, testing and deployment in order to champion successful execution<li>planning of application changes, development, and installation of upgrades and new releases<li>recommending changes to current techniques, procedures, or efforts<li>creating reports, dashboards, and analytics to support business processes and company initiatives</ul><h1>integration services</h1><ul><li>integrating multiple systems with SFDC, including internal proprietary and third-party applications</ul><h1>deployment services</h1><ul><li>QA, testing, debugging, and developing user documentation related to the configuration and deployment of applications<li>educating partners and stakeholders in the workflows, business processes, and analytics enabled by assigned functions and applications</ul><h1>support services</h1><ul><li>customer support</ul>"
    },
    "cze": {
      "title": "služby",
      "text": "<h1>vývoj aplikací</h1><ul><li>návrh, implementace a nasazení nových salesforce.com (SFDC) aplikací s použitím Apex, Visualforce, Aura a Lightning Web Components frameworků za účelem plného využití schopností SaaS SFDC platformy<li >údržba, modifikace a administrace stávajících řešení<li>day-to-day kontakt pro partnerské projektové týmy, jakož i další komunikační kanály</ul><h1>konzultantské služby</h1><ul><li>analýza, návrh, konfigurace, testování a nasazení řešení za účelem úspěšné implementace podnikové strategie<li>plánování systémových změn, vývoj a instalace upgradů a nových verzí aplikací<li>doporučení změn zažitých technik a procedur za účelem optimalizace efektivity procesů<li>vyhotovení reportů, dashboardů, a potřebné analytiky stávajících i nových firemních iniciativ</ul><h1>integrační služby</h1><ul><li>integrace SFDC řešení s dalšími aplikacemi a databázemi, včetně vlastních interních systémů i systémů třetích stran</ul><h1>služby spojené s nasazením řešení</h1><ul><li>QA, testování, debugging, a vývoj uživatelské dokumentace spojené s konfigurací a nasazením aplikací<li>kontinuální vzdělávání partnerů a jejich stakeholderů na aplikační workflows, byznys procesy, a analytiku spojenou s novými aplikačními funkcionalitami</ul><h1>podpůrné služby</h1><ul><li>centrum podpory zákazníků s využitím dohodnutých komunikačních kanálů (e-mail, telefon, on-site podpora)"
    },
    "svk": {
      "title": "služby",
      "text": "<h1>vývoj aplikácií</h1><ul><li>návrh, implementácia a nasadenie nových salesforce.com (SFDC) aplikácií s použitím Apex, Visualforce, Aura a  Lightning Web Components frameworkov za účelom plného využitia schopností SaaS SFDC platformy<li>údržba, modifikácie a administrácia existujúcich riešení<li>day-to-day kontakt pre partnerské projektové tímy, ako aj ďaľšie komunikačné kanály</ul><h1>konzultantské služby</h1><ul><li>analýza, návrh, konfigurácia, testovanie a nasadenie riešení za účelom úspešnej implementácie podnikovej stratégie<li>plánovanie systémových zmien, vývoj a inštalácia upgradov a nových verzií aplikácií<li>odporučenie zmien zaužívaných techník a procedúr za účelom optimalizácie efektivity procesov<li>vyhotovenie reportov, dashboardov, a potrebnej analytiky existujúcich aj nových firemných iniciatív</ul><h1>integračné služby</h1><ul><li>integrácia SFDC riešení s ďaľšími aplikáciami a databázami, vrátane vlastných interných systémov ako aj systémov tretích strán</ul><h1>služby spojené s nasadením riešení</h1><ul><li>QA, testovanie, debugging, a vývoj užívateľskej dokumentácie spojenej s konfiguráciou a nasadením aplikácií<li>kontinuálne vzdelávanie partnerov a ich stakeholderov na aplikačné workflows, biznis procesy, a analytiku spojenú s novými aplikačnými funkcionalitami</ul><h1>podporné služby</h1><ul><li>centrum podpory zákazníkov s využitím dohodnutých komunikačných kanálov (e-mail, telefón, on-site podpora)</ul>"
    },
    "deu": {
      "title": "services",
      "text": "<h1>Anwendungsentwicklung</h1><ul><li>Design, Implementierung und Bereitstellung neuer salesforce.com (SFDC)-Anwendungen unter Verwendung von Apex-, Visualforce-, Aura- und Lightning-Webkomponenten-Frameworks, um die Fähigkeiten der SaaS-SFDC-Plattform voll auszuschöpfen <li >Wartung, Modifikation und Administration bestehender Lösungen<li>täglicher Kontakt zu Partner-Projektteams sowie anderen Kommunikationskanälen</ul><h1>Beratungsleistungen</h1><ul><li>Analyse, Design, Konfiguration, Test und Einsatz von Lösungen zur erfolgreichen Umsetzung der Unternehmensstrategie<li>Planung von Systemänderungen, Entwicklung und Installation von Upgrades und neuen Versionen von Anwendungen<li>Empfehlung von Änderungen erfahrene Techniken und Verfahren, um die Effektivität von Prozessen zu optimieren<li>Erstellung von Berichten, Dashboards und notwendigen Analysen bestehender und neuer Unternehmensinitiativen</ul><h1>Integrationsdienste</h1><ul><li>Integration von SFDC-Lösungen mit anderen Anwendungen und Datenbanken, einschließlich eigener interner Systeme und Systeme von Drittanbietern</ul><h1>Dienste im Zusammenhang mit der Bereitstellung von Lösungen</h1><ul><li>QA, Tests, Debugging und Entwicklung von Benutzerdokumentation im Zusammenhang mit der Konfiguration und Bereitstellung von Anwendungen<li>kontinuierliche Schulung von Partnern und ihren Interessenvertretern zu Anwendungsworkflows, Geschäftsprozessen und Analysen im Zusammenhang mit neuen Anwendungsfunktionen</ul><h1>Supportservices</h1><ul><li>Kundensupportzentrum unter Verwendung vereinbarter Kommunikationskanäle ( E-Mail, Telefon, Vor-Ort-Support)"
    }
  },
  {
    "eng": {
      "title": "expertise",
      "text": "<p><h1>Our consultants and developers have long years experience with various CRM and ERP solutions</h1> Skills and approaches we enforce and appreciate within our project teams are:<ul><li>salesforce.com (SFDC) certifications for solution architects, developers, administrators<li>SCRUM certifications for project leads<li>strong and solution oriented  analytical thinking<li>keeping track with latest programming techniques and frameworks <li>affinity for technology and education<li>ability to work in a fast paced, demanding, and fluid environment</ul>"
    },
    "cze": {
      "title": "odbornost",
      "text": "<p>Naši konzultanti a vývojáři disponují dlouholetými zkušenostmi s paletou CRM a ERP systémů. Expertíza a přístupy, které jsou v našich týmech ceněny a vyžadovány jsou:<ul><li>salesforce.com (SFDC) certifikace architektů, vývojářů a administrátorů<li>SCRUM certifikace projektových manažerů<li>analytické myšlení s orientací na nalézání řešení< li> sledování a využívání nejnovějších trendů v programovacích technikách a frameworkech<li>afinita k technologii a kontinuálnímu vzdělávání<li>schopnost pracovat v rychle se měnícím a na požadavky náročném prostředí </ul>"
    },
    "svk": {
      "title": "odbornosť",
      "text": "<p>Naši konzultanti a vývojári disponujú dlhoročnými skúsenosťami s paletou CRM a ERP systémov. Expertíza a prístupy, ktoré sú v našich tímoch cenené a vyžadované sú:<ul><li>salesforce.com (SFDC) certifikácie  architektov, vývojárov a administrátorov<li>SCRUM certifikácie projektových manažérov<li>analytické myslenie s orientáciou na nachádzanie riešení<li> sledovanie a využívanie najnovších trendov v programovacích technikách a frameworkoch<li>afinita k technológii a kontinuálnemu vzdelávaniu<li>schopnosť pracovať v rýchlo sa meniacom a na požiadavky náročnom prostredí </ul>"
    },
    "deu": {
      "title": "expertise",
      "text": "<p>Unsere Berater und Entwickler verfügen über langjährige Erfahrung mit verschiedenen CRM- und ERP-Systemen. Expertisen und Vorgehensweisen, die in unseren Teams geschätzt und gefordert werden, sind:<ul><li>salesforce.com (SFDC) Zertifizierung von Architekten, Entwicklern und Administratoren<li>SCRUM Zertifizierung von Projektmanagern<li>analytisches und lösungsorientiertes Denken <li> Nutzung der aktuellsten Programmiertechniken und Frameworks<li> Affinität zu Technologie und kontinuierliche Weiterbildung<li> Fähigkeit, in einem sich schnell verändernden und anspruchsvollen Umfeld zu arbeiten </ul>"
    }
  },
  {
    "eng": {
      "title": "contact",
      "text": "<h1> We would like to hear from you: </h1>+421 905 251 491<br>+421 905 585 952<br>+421 948 219 011<br><br><br><br><a href='mailto:info@dasforce.com'>info@dasforce.com</a><br>"
    },
    "cze": {
      "title": "kontakt",
      "text": "<h1> Rádi bychom od vás slyšeli: </h1>+421 905 251 491<br>+421 905 585 952<br>+421 948 219 011<br><br><br><br><a href='mailto:info@dasforce.com'>info@dasforce.com</a><br>"
    },
    "svk": {
      "title": "kontakt",
      "text": "<h1> Radi by sme od vás počuli: </h1>+421 905 251 491<br>+421 905 585 952<br>+421 948 219 011<br><br><br><br><a href='mailto:info@dasforce.com'>info@dasforce.com</a><br>"
    },
    "deu": {
      "title": "kontakt",
      "text": "<h1> Wir würden gerne von Ihnen hören: </h1>+421 905 251 491<br>+421 905 585 952<br>+421 948 219 011<br><br><br><br><a href='mailto:info@dasforce.com'>info@dasforce.com</a><br>"
    }
  }
]
